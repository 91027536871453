import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentRenderer from "../components/ContentRenderer"
import Footer from "../components/Footer"

const DynamicPage = ({ data }) => {
  const page = data.allStrapiDynamicPage.edges[0].node

  return (
    <Layout altNav={page.AltNav}>
      <Seo title={page.Title} />

      <ContentRenderer zone={page.Content} />

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allStrapiDynamicPage(filter: { Slug: { eq: $slug } }) {
      edges {
        node {
          Slug
          Title
          Content
          AltNav
        }
      }
    }
  }
`

export default DynamicPage
